import React from "react";

import {
  HeaderContainer,
  OptionsContainer,
  OptionLink,
  AppNameContainer,
  CompanyCredentialsContainer,
} from "./header.styles";

const Header = ( {buttonElement, } ) => {
  return (
    <HeaderContainer>
      <CompanyCredentialsContainer>

        <AppNameContainer to="/">Smart</AppNameContainer>
      </CompanyCredentialsContainer>

      <OptionsContainer>
        <OptionLink to="/"> HOME </OptionLink>
        {/* <OptionLink to="/#"> DOCS </OptionLink> */}
        <OptionLink to="/dashboard"> DASHBOARD </OptionLink>
        {/* <OptionLink to="/#"> CONTACT </OptionLink> */}
      </OptionsContainer>
    </HeaderContainer>
  );
};

export default Header;
