import styled from "styled-components";

import { Link } from "react-router-dom";

export const HeaderContainer = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;

`;

export const CompanyCredentialsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;

`;

export const LogoContainer = styled(Link)`
  // height: 100%;
  // width: 70px;

  .material-icons.md-48 {
    font-size: 48px;
    // color: #66fcf1;
  }
`;

export const AppNameContainer = styled(Link)`
  // color: #66fcf1;
  // width: 40%;
  margin-left: 20px;
  font-size: 22px;
  font-size: 30px !important;
  color: #12130e;
`;

export const OptionsContainer = styled.div`
  // width: 60%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #12130e;
`;

export const OptionLink = styled(Link)`
  font-size: 22px;
  // color: #66fcf1;
  padding: 10px 15px;
  cursor: pointer;
  color: #12130e;

  &:hover {
    color: #684be8;
  }
`;
