import React from "react";

import { Card } from './appCard.styles';

const AppCard = ( {appName, toPath} ) => {
    return (
        <Card to={`${toPath}`}>
            {appName}
        </Card>
    );
};

export default AppCard
