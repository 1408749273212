import styled from "styled-components";

export const UVAppContainer = styled.div`
    text-align: center;
    width: 60%;
    height: auto;
    margin: auto;
`;

export const ConverterContainer = styled.div`
    background-color: #FFFFFF;
    border-radius: 20px;
    margin: 1px;
    padding: 15px;
    box-shadow: 0 0 30px black;
`;

export const ImgContainer = styled.img`
    width: 60%;
`;