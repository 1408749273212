import React from "react";

import { WelcomeContainer, WelcomeInfoContainer, WelcomeLine } from "./welcome.styles";

export const Welcome = () => (
  <WelcomeContainer>
    <h1>Custom excel automation</h1>
    <WelcomeInfoContainer>
      <p>Click on dashboard to make use of your custom apps.</p>
    </WelcomeInfoContainer>
    <WelcomeLine />
  </WelcomeContainer>
);
