import styled from 'styled-components';

import { Link } from "react-router-dom";

export const UserAvailableApps = styled.div`
    float: left;
    width: 33.33%;
`;

export const UserMessage = styled.div`
    padding: 20px;
    color: #ffff;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const DashboardContainer = styled.div`

`;

export const UserAvailableAppsContainer = styled.div`
    &:after {
        content: "";
        display: table;
        clear: both;
    }
`;
