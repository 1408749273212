import styled from "styled-components";

export const WelcomeContainer = styled.div`
  padding: 20px;
  color: #ffff;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WelcomeInfoContainer = styled.div`
  font-size: 20px;
  color: #12130e;
  letter-spacing: 2px;
`;

export const WelcomeLine = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  width: 10%;
  text-algin: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
`;
