import { createGlobalStyle } from "styled-components";
import img from "./assets/images/blob-scene-haikei-1.svg";

export const GlobalStyle = createGlobalStyle`
    body {
        font-family: 'Poppins', sans-serif;
        padding: 20px 60px;

        // background-color: #1F2833;
        background-size: cover;
        background-position: center;
        background-image: url(${img});
        background-attachment: fixed;
    }

    div::-webkit-scrollbar {
        width: 6px;               /* width of the entire scrollbar */
      }
      
      div::-webkit-scrollbar-track {
        background: #6B6E70;        /* color of the tracking area */
      }
      
      div::-webkit-scrollbar-thumb {
        background-color: black;    /* color of the scroll thumb */
        border-radius: 80px;       /* roundness of the scroll thumb */
        border: 1px solid orange;  /* creates padding around scroll thumb */
      }

    body::-webkit-scrollbar {
        width: 10px;               /* width of the entire scrollbar */
      }
      
      body::-webkit-scrollbar-track {
        background: grey;        /* color of the tracking area */
      }
      
      body::-webkit-scrollbar-thumb {
        background-color: black;    /* color of the scroll thumb */
        border-radius: 80px;       /* roundness of the scroll thumb */
        border: 1px solid #86C232;  /* creates padding around scroll thumb */
      }

    a {
        text-decoration: none;
        // color: white;
        // color: #86C232;
    }

    .p {
        color: white;
    }

    * {
        box-sizing: border-box;
    }
`;
