import React, { useContext } from "react";

import { UVAppContainer, ConverterContainer, ImgContainer } from "./uurverduidelijkingapp.styles";

import UploadAndConvertFileToS3WithReactS3 from "../../components/s3Dropper/s3Dropper.component";

import { PRESIGNED_URL_API_ENDPOINT, APP_ORCH_API_ENDPOINT } from '../../urlEndpoints';

import { Auth, Hub } from 'aws-amplify';

import { userContext } from "../../contexts/userContext";

const UurverduidelijkingAppPage = () => {
  const { user } = useContext(userContext);

  return (
    <UVAppContainer>
    <h1>Uurverduidelijking Apps</h1>
    <ConverterContainer>
          <h2>Uurverduidelijking Generator</h2>
          {user ? 
            <UploadAndConvertFileToS3WithReactS3 
            apiKey={user.key}
            userName={user.username}
            appName={'uurverduidelijkingAppGenerator'}
            recipientMailAddress={user.email}
            CUSTOMERBUCKETNAME         = 'veldkbucket'
            UPLOADWRITEPATH            = 'uurverduidelijkingapp/UV-Input-Files'
            PRESIGNED_URL_API_ENDPOINT = {PRESIGNED_URL_API_ENDPOINT}
            CONVERSION_API_ENDPOINT   = {APP_ORCH_API_ENDPOINT}
            CONVERSION_READ_PATH       = 'uurverduidelijkingapp/UV-Input-Files'
            CONVERSION_WRITE_PATH       = 'uurverduidelijkingapp/UV-Output-Files'
            MULTIPLEFILES = {true}
            CONVERSION_NEEDED = {true}
            /> : null} 
          <div>
            <p>Gebruik deze app om uurverduidelijkingsregisters te genereren. Enkele aandachtspunten:</p>
            <li>Je kan de Timetracking excels uit Teamleader downloaden en zo slepen in het drop vakje.</li>
            <li>Als er een offerte beschikbaar is, download ze als pdf en verander de naam naar de project ID.</li>
            <p>Voorbeeld project Vanhastel-Demey:</p>
            <li>Teamleader timetracking excel: Project 21091_-_B_-_Vanhastel_-_Demey_timetracking-4.xlsx</li>
            <li>Teamleader PDF: 21091.pdf</li>
          </div>

      </ConverterContainer>
      <br/>
      <br/>
      <ConverterContainer>
          <h2>Teamleader input files</h2>
          {user ? 
            <UploadAndConvertFileToS3WithReactS3 
            apiKey={user.key}
            userName={user.username}
            appName={'uurverduidelijkingAppInputFiles'}
            recipientMailAddress={user.email}
            CUSTOMERBUCKETNAME         = 'veldkbucket'
            UPLOADWRITEPATH            = 'uurverduidelijkingapp/UV-TL-Files'
            PRESIGNED_URL_API_ENDPOINT = {PRESIGNED_URL_API_ENDPOINT}
            CONVERSION_API_ENDPOINT   = {'None'}
            CONVERSION_READ_PATH       = 'None'
            CONVERSION_WRITE_PATH       = 'None'
            MULTIPLEFILES = {true}
            CONVERSION_NEEDED = {false}
            /> : null} 
          <div>
            <p>Gebruik deze app om de brondata een update te geven. Dit wil je doen als er nieuwe projecten zijn/nieuwe informatie rond contacten of bedrijven.</p>
            <p>De namen van de teamleader files moeten zeker in de volgende vormen:</p>
            <li>companies.xlsx</li>
            <li>contacts.xlsx</li>
            <li>invoices.xlsx</li>
            <li>sales.xlsx</li>
          </div>
      </ConverterContainer>
    </UVAppContainer>
  );
};

export default UurverduidelijkingAppPage;
