import React, {useEffect, useState, useContext} from "react";

import { Auth, Hub } from 'aws-amplify';

// import { Authenticator } from '@aws-amplify/ui-react';
// import '@aws-amplify/ui-react/styles.css'

import { UserAvailableApps, UserMessage, DashboardContainer, UserAvailableAppsContainer } from "./dashboard.styles";
import AppCard from "../../components/appCard/appCard.component"

import { userContext } from "../../contexts/userContext";

import {
    HeaderContainer,
    OptionsContainer,
    OptionLink,
    AppNameContainer,
    CompanyCredentialsContainer,
  } from "../../components/header/header.styles";

const DashboardPage = () => {
  const { user } = useContext(userContext)

  let displayName = "";

  if (user.email === "arnaudlangeraert@hotmail.be") {
    displayName = "Arnaud";
  } else if (user.email === "info@verhelle-nv.be") {
    displayName = "Verhelle";
  };

  return (
    <DashboardContainer>
        <UserMessage>{displayName}'s apps</UserMessage>

        <UserAvailableAppsContainer>
            {user ? (user.enabledApps.includes('matisApp') ? 
            <UserAvailableApps>
                <AppCard appName='MatisApp' toPath="/dashboard/matisapp"/>
            </UserAvailableApps> : null) : null}

            {user ? (user.enabledApps.includes('uurverduidelijkingApp') ? 
            <UserAvailableApps>
                <AppCard appName='uurverduidelijkingApp' toPath="/dashboard/uurverduidelijkingApp"/>
            </UserAvailableApps> : null) : null}
        </UserAvailableAppsContainer>
    </DashboardContainer>
  );
};

export default DashboardPage;
