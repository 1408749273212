import "./App.css";

import { withAuthenticator, Button } from "@aws-amplify/ui-react";
import { Auth, Hub } from 'aws-amplify';
import "@aws-amplify/ui-react/styles.css"

import React, { useState, useEffect, useMemo } from "react";
import { Routes, Route } from "react-router-dom";

import Header from "./components/header/header.component"
import HomePage from "./pages/homepage/homepage.component"
import DashboardPage from "./pages/dashboard/dashboard.component";
import MatisAppPage from "./pages/matisapp/matisapp.component";
import UurverduidelijkingAppPage from "./pages/uurverduidelijkingapp/uurverduidelijkingapp.component";

import { userContext } from "./contexts/userContext";

import { GlobalStyle } from "./global.styles";


const GetCredentials = async (username, useremail, usersub, url) => {
  const axios = require("axios").default;

  const credentialResponse = await axios({
    method: 'POST',
    url: url,
    params: {
      customerEmailAddress: useremail
    },
    headers: {
      'x-api-key': usersub
    }
  });

  return credentialResponse
};


const App = ( { signOut } ) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    console.log('Fetching current user...');
    
    // Set the user in the context
    Auth.currentAuthenticatedUser()
      .then((user) => {
          console.log(user);
          console.log('User fetched!')
          const credentials = GetCredentials(user.username, user.attributes.email, user.attributes.sub, 'https://8rcydvm225.execute-api.eu-central-1.amazonaws.com/prod/');
          credentials
          .then((creds) => {
            setUser({"username": user.username, "email": user.attributes.email, "sub": user.attributes.sub, "key": creds.data.key, "enabledApps": creds.data.enabledApps});
            }
          ).catch(e => console.log(e))
        }
      ).catch(e => console.log(e))
  }, []);

  console.log("CREDENTIALS: ", user);

  const value = useMemo(() => ({user, setUser}), [user, setUser])

  return (
    <div className="App">
      <GlobalStyle />
      <Header />
      <userContext.Provider value={value}>
        <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route path="dashboard" element={<DashboardPage/>} />
            <Route path="dashboard/matisapp" element={<MatisAppPage />} />
            <Route path="dashboard/uurverduidelijkingApp" element={<UurverduidelijkingAppPage />} />
        </Routes>
      </userContext.Provider>
      <div className="Sign-out-button">
        <Button onClick={signOut}>Sign out</Button>
      </div>
    </div>
  )
};

export default withAuthenticator(App);
