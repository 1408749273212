import React, { useState, useRef, useMemo } from "react";
import S3FileUpload, { uploadFile } from "react-s3";
import S3 from 'react-aws-s3';


import 'react-dropzone-uploader/dist/styles.css';
import { DropZoneContainer } from './s3Dropper.styles';

import Dropzone from 'react-dropzone-uploader';
import {useDropzone} from 'react-dropzone';

import { } from "./s3Dropper.styles"
import { render } from "@testing-library/react";

import { Auth } from 'aws-amplify';

import { TECH_ERR_1 } from '../../errorMessages';

window.Buffer = window.Buffer || require("buffer").Buffer;

const ConvertExcelToCsvFormatExtension = (fileName) => {
    if (fileName.slice(-3) === 'csv') {
        return fileName;
    } else if (fileName.slice(-4) === 'xlsx') {
        const output = fileName.slice(0, fileName.length-4) + 'csv';
        return output;
    } else if (fileName.slice(-3) === 'xls') {
        const output = fileName.slice(0, fileName.length-3) + 'csv';
        return output;
    } else {
        alert('Unreadable file extension!');
    }
};

const ConvertExcelToExcelFormatExtension = (fileName) => {
    if (fileName.slice(-3) === 'csv') {
        return 'Actuele klantenlijst.xlsx';
    } else if (fileName.slice(-4) === 'xlsx') {
        // const output = fileName.slice(0, fileName.length-4) + 'xlsx';
        const output = 'Actuele klantenlijst.xlsx';
        return output;
    } else if (fileName.slice(-3) === 'xls') {
        // const output = fileName.slice(0, fileName.length-3) + 'xlsx';
        const output = 'Actuele klantenlijst.xlsx';
        return output;
    } else {
        alert('Unreadable file extension!');
    }
};

const UploadAndConvertFileToS3WithReactS3 = ({apiKey,
    userName, 
    appName, 
    recipientMailAddress, 
    CUSTOMERBUCKETNAME,
    UPLOADWRITEPATH,
    PRESIGNED_URL_API_ENDPOINT,
    CONVERSION_API_ENDPOINT,
    CONVERSION_READ_PATH,
    CONVERSION_WRITE_PATH,
    MULTIPLEFILES,
    CONVERSION_NEEDED
    }) => {

    const [number, setNumber] = useState(0)
    const [downloadFileList, setDownloadFileList] = useState([])
  
    // Return the current status of files being uploaded
    const handleChangeStatus = ({ meta, file }, status) => { 
        console.log(status, meta, file) 
        setNumber(number + 1) // A hack to make the files appear on the screen
    }

    // Return array of uploaded files after submit button is clicked
    const handleSubmit = async (files) => {
        const axios = require("axios").default;
        console.log('------------------------');
        console.log(files);
        console.log(files.length);
        
        const f = files[0];
        console.log(f.file);
        
        const fileNamesValues = []
        for (let i = 0; i<files.length; i++) {
            fileNamesValues.push(files.at(i).file.name);
        }
        
        console.log('fileNamesValues: ', fileNamesValues);
        console.log('------------------------');

        const fileNameValue = `${f.file.name}`;

        console.log(fileNameValue);
        console.log('PRESIGNED_URL_API_ENDPOINT: ', PRESIGNED_URL_API_ENDPOINT);
        // GET PRESIGNED URL
        const response = await axios({
            method: 'POST',
            url: PRESIGNED_URL_API_ENDPOINT,
            params: {
                fileNames: `${fileNamesValues}`,
                writePath: `${CUSTOMERBUCKETNAME}/${UPLOADWRITEPATH}`
            },
            headers: {
                'x-api-key': apiKey
        }});
    
        console.log('Response: ', response.data.uploadUrls);
        console.log('Response: ', response.data.keys);

        const uploadUrls = response.data.uploadUrls;

        const uploadResultList = [];
        let overallResultStatus = 200;

        for (let i=0; i<uploadUrls.length; i++) {
            let uploadURL = uploadUrls[i];
            let bodyOfFile = files[i].file

            // * PUT request: upload file to S3
            let UploadResult = await fetch(uploadURL, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST'
                },
                body: bodyOfFile,
            });
            console.log(`UploadResult ${i}:`, UploadResult);
            uploadResultList.push(UploadResult);
            files[i].remove()
            if (UploadResult.status !== 200) {
                // alert("Er is iets misgelopen met het uploaden van je files. Check de files en probeer het nog eens of contacteer de helpdesk.");
                overallResultStatus = UploadResult.status;
                break;
            }
        }

        const responseFileName = response.data.keys[0]
        console.log("responseFileName", responseFileName)
        
        // f.remove()
        try {
            if (overallResultStatus === 200) {
                if (CONVERSION_NEEDED) {
                    // CONVERT FILE TO CLEAN FILE
                    let fullConversionReadPath = ''
                    let fullConversionWritePath = ''
                    // SECTION FOR MATIS
                    if (appName === 'matisAanvoer' 
                        || appName === 'matisAfvoer' 
                        || appName === 'matisAanvoerUpdate' 
                        || appName === 'afvoerToAfvalstofregister' 
                        || appName === 'aanvoerToAfvalstofregister') {
    
                        if (appName === 'matisAanvoer' || appName === 'matisAfvoer' || appName === 'aanvoerToAfvalstofregister' || appName === 'afvoerToAfvalstofregister') {
                            fullConversionReadPath = CONVERSION_READ_PATH + responseFileName;
                            fullConversionWritePath = CONVERSION_WRITE_PATH + ConvertExcelToCsvFormatExtension(responseFileName)
                        } else {
                            fullConversionReadPath = CONVERSION_READ_PATH + responseFileName;
                            fullConversionWritePath = CONVERSION_WRITE_PATH + ConvertExcelToExcelFormatExtension(responseFileName)
                        }
        
                        const fileConversionResult = await axios({
                            method: 'POST',
                            url: CONVERSION_API_ENDPOINT,
                            params: {
                                readBucketName: CUSTOMERBUCKETNAME, 
                                readPathName: fullConversionReadPath,
                                writeBucketName: CUSTOMERBUCKETNAME,
                                writePathName: fullConversionWritePath,
                                userName,
                                appName,
                                recipientMailAddress
                            },
                            headers: {
                                'x-api-key': apiKey
                            }});
                        
                        console.log(fileConversionResult);
                            
                        alert('Conversie success! Je zult je bestanden zodadelijk ontvangen via mail.');
                    }
    
                    // SECTION FOR THE REST
    
                    else {
                        const fileConversionResult = await axios({
                            method: 'POST',
                            url: CONVERSION_API_ENDPOINT,
                            params: {
                                appSpecificParams: 
                                    {readBucketName: CUSTOMERBUCKETNAME, 
                                    writePath: CONVERSION_WRITE_PATH,
                                    readPath: CONVERSION_READ_PATH,
                                    fileNames: fileNamesValues
                                    },
                                userName,
                                appName,
                                recipientMailAddress
                            },
                            headers: {
                                'x-api-key': apiKey
                            }});
                            
                        console.log(fileConversionResult);
                        
                        if (fileConversionResult.status === 201) {
                            alert('Conversie success!');
                            const convertedFiles = fileConversionResult.data.appResponse.convertedFilesInfo;
                            console.log("convertedFiles: ", convertedFiles)
    
                            setDownloadFileList(convertedFiles)
    
                            // for (let i=0; i<convertedFiles.length;i++) {
                            //     downloadUrlList.push(convertedFiles[i])
                            // }
                        }
    
                    }
                        
                } else {
                    alert('Je bestanden zijn zonder problemen geupload!');
                    }
            } else {
            alert(TECH_ERR_1);
            }
        } catch (err){
            alert(TECH_ERR_1);
            console.log(err);
        }
    };

    return (
        <DropZoneContainer>
        <Dropzone 
            onChangeStatus={handleChangeStatus}
            onSubmit={handleSubmit}
            // maxFiles={1}
            multiple={MULTIPLEFILES}
            autoUpload={true}
            styles={{
                dropZone: { width: 400, height: 200, borderColor: "black"},
                dropzoneActive:{borderColor: "grey"}}}
            />
        <br/>
        {downloadFileList ? 
        downloadFileList.map((d, index) => { return (<li key={index}>
            {console.log(d)}
            {console.log(index)}
            {<a href={d.downloadUrl} key={index+500} download>{d.fileName}</a>}
        </li>)}) : null}
        </DropZoneContainer>
        );
    };

export default UploadAndConvertFileToS3WithReactS3;
