import React from "react";

import { HomePageContainer, HomePageInfoContainer } from "./homepage.styles";

import { Welcome } from "../../components/welcome/welcome.component";

const HomePage = () => {
  
  return (
    <HomePageContainer>
      <Welcome />
      <HomePageInfoContainer>
      </HomePageInfoContainer>
    </HomePageContainer>
  );
};

export default HomePage;
