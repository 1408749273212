import styled from 'styled-components';


import { Link } from "react-router-dom";

export const Card = styled(Link)`
    box-sizing: border-box;
    display: inline-block;
    text-align: center;
    padding: 50px 80px;
    color: white;
    background-color: #1c233c;
    border: 1px solid;
    border-color: #1c233c;
    border-radius: 20px;
    // font-family: sans-serif;
    // font-size: 16px;
    cursor: pointer;
    &:hover {
        box-shadow: 0 0 10px black;
    }
    
`;
